import axios from "axios";

/**
    * @function paymentPage
    * @param
    * @description This function is used to make the payment
*/
export const paymentPageService = async () => {
    try {
        const result = await axios.post(`user/learn-and-earn/subscribe/payment`, {});
        return result;
    } catch (error) {
        return error;
    }
};

/**
    * @function applyScratchCodeService
    * @param promo_code
    * @description This function is used to apply promo code
*/
export const applyScratchCodeService = async (scratch_code) => {
    
    try {
        const result = await axios.post(`user/learn-and-earn/subscribe/scratch-code`, {
            scratch_code
        });
        return result;
    } catch (error) {
        return error;
    }
};

/**
    * @function subscriptionStatus
    * @param
    * @description This function is used to fetch the status of user's subscription
*/
export const subscriptionStatusLearnAndEarn = async () => {
    try {
        const result = await axios.get(`user/learn-and-earn/subscription-status`, {});
        return result;
    } catch (error) {
        return error;
    }
};


/**
    * @function claimRewardService

    * @description This function is used to claim reward from Learn and Earn program
*/
export const claimRewardService = async (
    {
        withdraw_points,
        official_document_type,
            document_file,
            document_file_second ,
            full_name,
            email,
            phone_number,
            address,
            tax_id,
            bank_country,
            bank_name,
            bank_account_number,
            bank_account_holder_name,
            swift_code,
            has_reskills_user,
            terms_and_conditions_accepted
    }) => {
    
    try {
        const result = await axios.post(`user/withdraw-points`, {
            withdraw_points,
            official_document_type,
            document_file,
            document_file_second ,
            full_name,
            email,
            phone_number,
            address,
            tax_id,
            bank_country,
            bank_name,
            bank_account_number,
            bank_account_holder_name,
            swift_code,
            has_reskills_user,
            terms_and_conditions_accepted
        }, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });
        return result;
    } catch (error) {
        return error;
    }
};
