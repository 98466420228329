/* eslint-disable no-undef */
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
//importing styles
import "../../assets/scss/pages/onLive/onLive.scss";

//impoting images
import searchIcon from "../../assets/images/Onlive/searchIcon2.svg";

//importing components
import LiveCourse from "../../components/Course/LiveCourse/LiveCourse";
import BannerWithText from "../../components/Banner/BannerWithText";
import Paginate from "../../components/common/paginate/Paginate";
import Layout from "../../components/common/layout/layout";

//importing services
import { getClassService } from "../../services/dashboard.service";
import {
  selectClassList,
  setClassList,
} from "../../redux/classSlice";
import { selectBannerList } from "../../redux/bannerSlice";
import { getLanguageByClass } from "../../services/class.service";
import { selectCategoryList } from "../../redux/categorySlice";
import TodayClasses from "../../components/common/todayClasses/TodayClasses";
import UpComingClasses from "../upcomingClasses/UpcomingClasses";

const ExploreAllClasses = () => {
  const { slug } = useParams();
  const dispatch = useDispatch();
  const Classes = useSelector(selectClassList);
  const categories = useSelector(selectCategoryList);
  const [activeTab, setActiveTab] = useState();
  const [totalItem, setTotalItem] = useState();
  const [language, setLanguage] = useState();
  const [selectedCategory, setSelectedCategory] = useState();

  const onLiveBanner = useSelector(selectBannerList)?.filter(
    // (item) => item?.type?.toLowerCase() === "onlive"
    (item) => item?.type?.toLowerCase() === slug
  );
  const date = moment(new Date()).format("YYYY-MM-DD");
  
  useEffect(() => {
    const fetchClasses = async () => {
      try {
        const result = await getClassService({
          filter: slug,
          perPageItem: 12,
          page: 1,
          language: activeTab,
          category: selectedCategory,
          up_coming_date: date,
          up_coming_classes: slug?.toLowerCase() === 'onlive' ? true : false
        });
        setTotalItem(result?.data?.classes?.total);
       
          // dispatch(setClassList(result?.data?.classes?.data));
        
      } catch (error) {
        console.log(error);
      }
    };
    fetchClasses();
    // eslint-disable-next-line
  }, [activeTab, selectedCategory ,slug]);

  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 500);
    };
  };
  // let timer;
  const handleSearch = async (event) => {
    const { value } = event?.target;
    const result = await getClassService({
      filter: slug,
      perPageItem: 12,
      page: 1,
      searchQuery: value,
      up_coming_date: date,
      up_coming_classes: slug?.toLowerCase() === 'onlive' ? true : false
    });
    setTotalItem(result?.data?.classes?.total);
    dispatch(setClassList(result?.data?.classes?.data));
  };

  // eslint-disable-next-line
  const optimisedVersion = useCallback(debounce(handleSearch), []);

  useEffect(() => {
    const fetchLanguageByClass = async () => {
      const result = await getLanguageByClass({
        classSlug: slug,
      });
      setLanguage(result?.data?.classesByLanguages);
    };
    fetchLanguageByClass();
    // eslint-disable-next-line
  }, []);

  const totalItems = language?.reduce(
    (accumulator, currentValue) => accumulator + currentValue?.languageCount,
    0
  );

  return (
    <>
      <Layout>
        <div className="onLive-main-container custom-container max-w-home">
          <BannerWithText
            title={onLiveBanner ? onLiveBanner[0]?.title : ''}
            description={onLiveBanner ? onLiveBanner[0]?.description : ''}
            image={onLiveBanner ? onLiveBanner[0]?.image : ''}
          />

          {slug.toLowerCase() !== 'onthego' && <LiveCourse />}
          {(slug.toLowerCase() !== 'onthego' && slug.toLowerCase() !== 'onlive') && <UpComingClasses />}

          <div className="onLive-inner-container mx-md-5 my-md-5 mx-3 my-3">
            <h2 className="fw-bold fs-4 mb-4">
              {slug.toLowerCase() === 'onlive' ? "Upcoming OnLIVE" : Classes ? Classes[0]?.class_type?.name : ''}
            </h2>
            <div className="onlive-tab-container d-flex flex-wrap align-items-center gap-2">
              <div
                onClick={() => setActiveTab(0)}
                className={`${!activeTab && "active-bg"
                  } d-flex align-items-center gap-2 px-3 py-1 cursor-pointer`}
              >
                <span className={`${activeTab ? "active-text" : "text-secondary"}`}>
                  All
                </span>
                {/* <span
                  className={`${activeTab && "active-text"
                    } border border-secondary rounded-btn px-2`}
                >
                  {totalItems}
                </span> */}
              </div>
              {language?.map((item) => {
                return (
                  <div
                    onClick={() => setActiveTab(item?.language_id)}
                    className={`${activeTab === item?.language_id && "active-bg"
                      } d-flex align-items-center gap-2 px-3 py-1 cursor-pointer`}
                  >
                    <span
                      className={`${activeTab ? "active-text" : "text-secondary"}`}
                    >
                      {item?.name}
                    </span>
                    {/* <span
                      className={`${activeTab && "active-text"
                        } border border-secondary rounded-btn px-2`}
                    >
                      {item?.languageCount}
                    </span> */}
                  </div>
                );
              })}
            </div>
            <div className="onlive-filter-container d-flex align-items-center justify-content-between  mb-4 mt-3 ">
              <div className="search-container">
                <img src={searchIcon} alt="icon" />
                <input
                  className="form-control px-5"
                  type="search"
                  placeholder="Search title"
                  aria-label="Search"
                  onChange={optimisedVersion}
                />
              </div>
              <div className="dropdown-container">
                <select
                  className="dropdown-itemMenu"
                  onChange={(e) => setSelectedCategory(e.target.value)}
                >
                  <option>Select category</option>
                  {categories?.map((item) => {
                    return (
                      <option className="cursor-pointer" value={item?.slug}>
                        {item?.name}
                      </option>
                    );
                  })}
                  {/* <option className="dropdown-items">Eng</option>
                  <option className="dropdown-items">Malay</option> */}
                </select>
              </div>
            </div>

            <Paginate totalItem={totalItem} slug={slug}  activeTab={activeTab} selectedCategory={selectedCategory}/>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default ExploreAllClasses;
