import { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaRegCircleCheck } from "react-icons/fa6";
import { IoIosCloseCircleOutline, IoIosCheckmarkCircle } from "react-icons/io";
import { IoWarningOutline } from "react-icons/io5";
import { useParams, Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useForm, useFieldArray } from 'react-hook-form';
import classNames from "classnames";
import { useNavigate } from "react-router-dom";

import Layout from "../../components/common/layout/layout";
import Button from "../../components/common/button/Button";

import { getUser } from "../../utils/Auth.Service";

import { getAssessmentByClass, getAssessmentRecordByClass, submitAssessment } from "../../services/assessment.service";
import { getClassDetail } from "../../services/class.service";

const Assessment = () => {
    const { slug } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();


    const user = getUser();

    const [assessment, setAssessment] = useState();
    const [assessmentRecord, setAssessmentRecord] = useState([]);
    const [assessmentResult, setAssessmentResult] = useState();
    const [loading, setLoading] = useState(false);
    const [retryAttempt, setRetryAttempt] = useState(false);

    const { register, control, watch, reset, handleSubmit } = useForm({
        mode: "onBlur"
    });

    const { fields } = useFieldArray({
        control,
        name: "answers"
    });

    const allWatchedValues = watch({
        nest: true
    });

    const fetchClassDetail = async () => {
        const result = await getClassDetail(slug, user?.id);
    };

    const fetchAssessment = async () => {
        const result = await getAssessmentByClass({
            classname: slug
        });
        if(result?.data?.assessment?.status === 1){
            setAssessment(result?.data?.assessment);
        }
    };

    const fetchAssessmentRecord = async () => {
        const result = await getAssessmentRecordByClass({
            assessment_id: assessment?.id
        });
        if (result?.data?.questions) {
            let defaultValues = {};
            defaultValues.answers = result?.data?.questions?.map(item => {
                return {
                    user_answer: item?.user_answer
                }
            })
            reset({ ...defaultValues })
            setAssessmentRecord(result?.data?.questions)
        }
    };

    useEffect(() => {
        fetchAssessment();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (assessment) {
            // fetchAssessmentRecord();
            fetchClassDetail()
        }
        // eslint-disable-next-line
    }, [assessment]);

    const onSubmit = async (formdata) => {
        setLoading(true);
        const result = await submitAssessment({
            classSlug: slug,
            assessment_id: assessment?.id,
            answers: formdata?.answers?.filter(item => item?.user_answer)
        });
        setAssessmentResult(result?.data)
        if (result?.response?.data?.error) {
            toast.error(result?.response?.data?.error);
        } else {
            await fetchClassDetail()
            await fetchAssessmentRecord()
            toast.success("Assessment submitted successfully");

            setRetryAttempt(true)
        }
        setLoading(false);
    };

    return (
        <Layout>
            <div className={classNames("mx-md-5 mx-2 mt-2", {
                "d-none": assessmentResult && assessmentResult?.obtained_percentage >= assessmentResult?.required_percentage
            })}>
                <h5 className="fw-bold">{t("assessment")}</h5>
                <h5 className="fw-bold text-secondary mt-3">{assessment?.title}</h5>
            </div>
            {assessmentResult && assessmentResult?.obtained_percentage < assessmentResult?.required_percentage && assessmentResult?.assessment_result?.retry_attempt < 2 &&
                <div className="d-flex flex-column align-items-start bg-danger bg-opacity-10 p-3 px-5 mt-3">
                    <span className="fs-30 fw-bold">Your Result</span>
                    <div className="d-flex align-items-center">
                        <div className="d-inline">
                            <IoWarningOutline className="text-danger me-2" size={30} />
                        </div>
                        {assessmentResult?.assessment_result?.retry_attempt === 0 && <span className="text-danger fs-30 fw-bold">Sorry, you did not pass. You have one more chance to try again.</span>}
                        {assessmentResult?.assessment_result?.retry_attempt === 1 && <span className="text-danger fs-30 fw-bold">Unfortunately, you were not successful on both attempts.</span>}
                    </div>
                    <div className="d-flex flex-wrap justify-content-between align-items-center mt-3 w-100">
                        <div className="d-flex flex-column align-items-start">
                            <span className="fs-18 text-secondary">Grade Recieved</span>
                            <span className="fs-24 fw-bold text-danger">{Number.isInteger(assessmentResult?.obtained_percentage) ? assessmentResult?.obtained_percentage : assessmentResult?.obtained_percentage?.toFixed(2)}%</span>
                        </div>
                        <div className="d-flex flex-column align-items-start">
                            <span className="fs-18 text-secondary">To pass</span>
                            <span className="fs-24 fw-bold">{Number.isInteger(assessmentResult?.required_percentage) ? assessmentResult?.required_percentage : assessmentResult?.required_percentage?.toFixed(2)}% or Higher</span>
                        </div>
                        <div className={classNames({
                            "d-none": !retryAttempt || assessmentResult?.assessment_result?.retry_attempt > 0
                        })} style={{ width: "150px" }}>
                            <Button type="button" name={"Try Again"} onClick={() => {
                                setRetryAttempt(false)
                                setAssessmentResult()
                                reset()
                                window.location.reload();
                            }} />
                        </div>
                        <Link to={`/class/${slug}`}>
                            <div className={classNames({
                                "d-none": !retryAttempt || assessmentResult?.assessment_result?.retry_attempt === 0
                            })} style={{ width: "150px" }}>
                                <Button type="button" name={"Next"} />
                            </div>
                        </Link>
                    </div>
                </div>
            }
            {assessmentResult && assessmentResult?.obtained_percentage >= assessmentResult?.required_percentage &&
                <div className="d-flex flex-column align-items-start bg-success bg-opacity-10 p-3 px-5 mt-3">
                    <span className="fs-30 fw-bold">Result</span>
                    <div className="d-flex align-items-center">
                        <div className="d-inline">
                            <IoIosCheckmarkCircle className="text-success me-2" size={30} />
                        </div>
                        <span className="text-success fs-30 fw-bold">Congratulations! You Passed!</span>
                    </div>
                    <div className="d-flex flex-wrap justify-content-between align-items-center mt-3 w-100">
                        <div className="d-flex flex-column align-items-start">
                            <span className="fs-18 text-secondary">Grade Recieved</span>
                            <span className="fs-24 fw-bold text-success">{Number.isInteger(assessmentResult?.obtained_percentage) ? assessmentResult?.obtained_percentage : assessmentResult?.obtained_percentage?.toFixed(2)}%</span>
                        </div>
                        <div className="d-flex flex-column align-items-start">
                            <span className="fs-18 text-secondary">To pass</span>
                            <span className="fs-24 fw-bold">{Number.isInteger(assessmentResult?.required_percentage) ? assessmentResult?.required_percentage : assessmentResult?.required_percentage?.toFixed(2)}% or Higher</span>
                        </div>
                        <Link to={`/my-learning`}>
                            <div style={{ width: "150px" }}>
                                <Button type="button" name={"Next"} />
                            </div>
                        </Link>
                    </div>
                </div>
            }
            <div className="mx-md-5 mx-2 mt-2">
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <div className="mt-4 mx-4">
                        {assessment?.questions?.map((item, index) => {
                            return (
                                <div key={`question-${index}`}>
                                    <p className="">
                                        {index + 1}. {item?.question}
                                    </p>
                                    <div className="mx-2">
                                        {item?.options?.map((options, optionsIndex) => {
                                            return (
                                                <div className="d-flex flex-column align-items-start gap-2 mb-2" key={`question-${index}-${optionsIndex}`}>
                                                    <div className="d-flex align-items-center">
                                                        <input
                                                            type="radio"
                                                            className="radio-btn-primary"
                                                            value={options?.option}
                                                            id={`question-${index}-${optionsIndex}`}
                                                            {...register(`answers[${index}].user_answer`)}
                                                            disabled={retryAttempt}
                                                        />
                                                        <label className="fs-6 ms-2" htmlFor={`question-${index}-${optionsIndex}`}> {options?.option}</label>
                                                    </div>
                                                    {assessmentResult && assessmentRecord?.length > 0 && assessmentRecord?.some(assessmentItem => assessmentItem?.question === item?.question && options?.option === assessmentItem?.user_answer) &&
                                                        <>
                                                            {assessmentRecord?.find(assessmentItem => assessmentItem?.question === item?.question && options?.option === assessmentItem?.user_answer)?.correct_answer ?
                                                                <div className="d-flex align-items-center bg-success bg-opacity-10 p-2 w-100">
                                                                    <div className="d-inline">
                                                                        <FaRegCircleCheck className="text-success" />
                                                                    </div>
                                                                    <span className="ms-2 text-success">Correct</span>
                                                                </div>
                                                                :
                                                                <div className="d-flex align-items-center bg-danger bg-opacity-10 p-2 w-100">
                                                                    <div className="d-inline">
                                                                        <IoIosCloseCircleOutline className="text-danger" />
                                                                    </div>
                                                                    <span className="ms-2 text-danger">Incorrect</span>
                                                                </div>
                                                            }
                                                        </>
                                                    }
                                                    <input
                                                        type="text"
                                                        className="d-none"
                                                        value={item?.id}
                                                        {...register(`answers[${index}].question_id`)}
                                                    />
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <div className={classNames("align-items-center gap-2 mb-5 mt-4 mx-3", {
                        "d-flex": !retryAttempt,
                        "d-none": retryAttempt
                    })}>
                        <div style={{ width: "150px" }}>
                            <Button type="button" btnType="whiteButton" name={t("cancel")} onClick={() => navigate(-1)} />
                        </div>
                        <div style={{ width: "150px" }}>
                            <Button type="submit"
                                name={loading ? "Loading..." : t("submit")}
                                disable={!(allWatchedValues?.answers?.length > 0 && allWatchedValues?.answers?.some(item => item?.user_answer) && allWatchedValues?.answers?.filter(item => item?.user_answer)?.length ===allWatchedValues?.answers?.length  )}
                            />
                        </div>
                    </div>
                </Form>
            </div>
        </Layout>
    );
};

export default Assessment;
