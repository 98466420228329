import { Modal } from "react-bootstrap";
import Lottie from "lottie-react";
import { Link } from "react-router-dom";

//importing components
import Button from "../common/button/Button";

//importing styles
import "../../assets/scss/components/modal/recordingAlertModal.scss";

import { classDeRegistrationService } from "../../services/class.service";
import { toast } from "react-toastify";




const SecRegisterModal = ({ show, setShow ,secRegClass }) => {
    const handleClose = () => setShow(false);

    const handleClassDeRegistration = async (classId) => {
        // setLoading(true);
        const result = await classDeRegistrationService({
            classId: secRegClass?.conflicting_class_id,
        });
        if (result?.data?.message) {
            toast.success(result?.data?.message);
            setShow(false)
        }        
        else {
            toast.error(result?.response?.data?.error );
        }
        // setLoading(false);
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <div className="py-4 px-3">
                
                <div className="my-4">
                    <p className="fs-5 text-center ">You are already registered for a class at this time. To proceed, please click the 'Unregister' button to remove yourself from the <label className="fw-semibold text-primary">{secRegClass?.conflicting_class_name} </label> class.</p>
                    
                </div>
                <div className="d-flex align-items-center justify-content-end gap-2 mt-5">
          <div>
            <Button
              btnType="whiteButton"
              name="Cancel"
              onClick={handleClose}
            />
          </div>
          <div
            className="btn-container"
            style={{ width: "100px" }}

          >
            <Button name="Unregister" onClick={handleClassDeRegistration} />
          </div>
        </div>
                    
                
            </div>
        </Modal>
    );
};

export default SecRegisterModal;
