import React from 'react';
import Navbar from '../../components/common/navbar/Navbar';
import Account from '../account/Account';

const LearnAndEarnTnC = () => {
    return (
       
        <Account>
         <div className='tran-container d-flex custom-container  justify-content-center'> 
            <div className='main-reward-container mx-4'>
                <p className='fs-4 fw-bold '>Terms and Conditions for the Learn and Earn Program</p>
                <div className='my-4'>
                    <p className='fs-5 fw-bold'>1. Introduction</p>
                    <p >Welcome to the Learn and Earn Program! By participating, you agree to adhere to the following Terms and Conditions. Please read them carefully to understand your rights and
                        responsibilities.
                    </p>
                </div>
                <div className='my-4'>
                    <p className='fs-5 fw-bold'>2. Eligibility</p>
                    <p >2.1 Subscription Requirement: Participants must be an active ReSkills paid subscriber with a
                        2-year subscription priced at $24, obtained either through the ReSkills platform or via an
                        activation code.
                    </p>
                    <p className='my-4'>
                        2.2 Required Information: Participants must provide a valid identity number, email address,
                        contact number, and bank account information.
                    </p>
                    <p className='my-4'>
                        2.3 Active Participation: Participants must be willing and able to actively engage in the
                        program.
                    </p>
                </div>
                <div className='my-4'>
                    <p className='fs-5 fw-bold'>3. Activation</p>
                    <p >
                    3.1 Activation Process: To activate your participation, you must attend an OnLive class
                    within 48 hours of enrolling in the campaign.
                    </p>
                </div>
                <div className='my-4'>
                    <p className='fs-5 fw-bold'>4. Program Rewards</p>
                    <p >
                        4.1 Learning Reward: Earn 1 point for each completed OnLive session with certification, up
                        to a maximum of 100 points.
                    </p>
                    <p className='my-4'>
                        4.2 Referral Reward: Earn 2.4 points for each new user you refer who becomes a Learn and
                        Earn subscriber.
                    </p>
                    <p className='my-4'>
                        4.3 Mission Reward: Complete missions assigned by ReSkills to earn 0.2 points per mission,
                        up to a maximum of 100 points.
                    </p>
                    <p className='my-4'>
                        4.4 Point Conversion: 1 point is equivalent to 1 US dollar
                    </p>
                </div>
                <div className='my-4'>
                    <p className='fs-5 fw-bold'>5. Progress Tracking</p>
                    <p >
                        5.1 Monitoring: Participants' progress will be monitored throughout the program. Failure to
                        meet the requirements may result in a loss of points.

                    </p>
                </div>

                <div className='my-4'>
                    <p className='fs-5 fw-bold'>6. Claiming Rewards </p>
                    <p >
                        6.1 Points Withdrawal: Earned points can be claimed for rewards listed in the program's
                        rewards catalog.

                    </p>
                    <p className='my-4'>
                        6.2 Processing Time: Reward claim will be processed within 14 working days of the
                        withdrawal request, pending verification of completed activities.
                    </p>
                    <p className='my-4'>
                        6.3 Payout Rate: The payout rate is USD 1, with each USD 1 equivalent to MYR 4.5. The
                        total amount will be converted into other currencies based on this MYR exchange rate.
                    </p>
                    <p className='my-4'>
                        6.4 Eligibility for Claim: Participants may request a withdrawal once they have accumulated
                        a minimum of 30 points.
                    </p>
                    <p className='my-4'>
                        6.5 Transaction Fee: A 10% transaction service fee will be deducted from the total points
                        requested at the time of withdrawal.
                    </p>
                    <p className='my-4'>
                    6.6. Accuracy of Details: All details in the claim request form, including the personal and bank
                    information provided, must be accurate.
                    <br/>

                    <br/>

                    Any reward withdrawal rejection due to insufficient or incorrect information that incur charges
                    will be chargeback and deducted from your upcoming reward withdrawal processing.
                    <br/>

                    <br/>


                    Discrepancies may result in delays or denial of the withdrawal request.
                    </p>

                    <p className='my-4'>
                    6.7 Bank Transfer Rewards: For participants who redeem monetary rewards via bank transfer,
                    the transaction will be labelled as ReSkills Marketing Fee in their bank account statements.
                    </p>
                </div>
                <div className='my-4'>
                    <p className='fs-5 fw-bold'>7. Types of Rewards</p>
                    <p >
                        7.1 Rewards Catalog: Rewards may include cash, gift cards, merchandise, discount vouchers,
                        or other items listed in the program's rewards catalog. The catalog is updated periodically.
                    </p>
                </div>
                <div className='my-4'>
                    <p className='fs-5 fw-bold'>8. Delivery of Rewards</p>
                    <p >
                        8.1 Rewards Delivery: If applicable, rewards will be delivered to the address or email
                        associated with the participant's account. Digital rewards will be sent via email, while
                        physical rewards will be shipped to the provided address.
                    </p>
                </div>
                <div className='my-4'>
                    <p className='fs-5 fw-bold'>9. Referral Rewards</p>
                    <p >
                        9.1 Referral Process: Participants can share their unique invitation code or referral link found
                        in their account dashboard. Referral rewards points will be awarded only if the referred
                        individual successfully becomes a Learn and Earn subscriber.
                    </p>
                </div>
                <div className='my-4'>
                    <p className='fs-5 fw-bold'>10. Mission Rewards</p>
                    <p >
                        10.1 Mission Activities: Missions may include tasks assigned by ReSkills.
                    </p>
                </div>
                <div className='my-4'>
                    <p className='fs-5 fw-bold'>11. Learning Rewards</p>
                    <p >
                        11.1 Class Attendance: Points will be awarded for OnLive classes only.
                    </p>
                </div>
                <div className='my-4'>
                    <p className='fs-5 fw-bold'>12. Points Balance</p>
                    <p >
                        12.1 Balance Check: Participants can view their points balance by logging into their account
                        on the ReSkills platform.
                    </p>
                </div>
                <div className='my-4'>
                    <p className='fs-5 fw-bold'>13. Points Transfer</p>
                    <p >
                        13.1 Non-Transferability: Points earned in the Learn and Earn Program are non-transferable
                        to another ReSkills account. The points withdrawal request must be made from the respective
                        ReSkills account.
                    </p>
               </div>
               <div className='my-4'>
                    <p className='fs-5 fw-bold'>14. Technical Issues and Support</p>
                    <p >
                        14.1 Support Access: For technical issues or questions about the program, participants can
                        contact ReSkills support through the help section on the platform or via email at
                        support@reskills.com.
                    </p>
               </div>
               <div className='my-4'>
                    <p className='fs-5 fw-bold'>15. Learn & Earn Program Participation</p>
                    <p >
                        15.1 Refund Policy: Once enrolled in the Learn and Earn Program, participants are expected
                        to actively participate and adhere to these Terms and Conditions. Withdrawal from
                        participation is not permitted.
                    </p>
                    <p className='my-4'>
                        15.2 Program Compliance: Program administrators reserve the right to remove participants
                        from the program for non-compliance with the Terms and Conditions.
                    </p>
               </div>
               <div className='my-4'>
                    <p className='fs-5 fw-bold'>16. Referral Limit</p>
                    <p >
                        16.1 Unlimited Referrals: There is no limit to the number of referrals a participant can make.
                        Each referral must be a new user who successfully signs up for a two year premium
                        subscription and meets the learn and earn program requirements.
                    </p>
               </div>
               <div className='my-4'>
                    <p className='fs-5 fw-bold'>17. Updating Contact Details</p>
                    <p >
                        17.1 Contact Information: Participants must promptly update their contact details and bank
                        account information on the ReSkills platform to avoid issues with earning or withdrawing
                        points.
                    </p>
               </div>
               <div className='my-4'>
                    <p className='fs-5 fw-bold'>18. Additional Terms</p>
                    <p >
                        18.1 Review of Terms: Participants should regularly review these Terms and Conditions for
                        any updates or changes. Continued participation in the program constitutes acceptance of any
                        amended terms.
                    </p>
                    <p className='my-4'>
                        18.2 Right to Modify: ReSkills reserves the right to modify or add to these terms and
                        conditions at its discretion.
                    </p>
               </div>
            </div>
            
        </div>

        </Account>
       
    );
};

export default LearnAndEarnTnC;