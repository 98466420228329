import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ReactPaginate from "react-paginate";
import { setCoach, selectCoachDetail } from "../../../redux/coachSlice";
import FeaturedCoachCard from "../cards/FeaturedCoachCard/FeaturedCoachCard";
import { getCoachDetail } from "../../../services/coach.service";
import "../../../assets/scss/components/paginate/paginate.scss";
import NoData from "../noData/NoData";
import CoachCourceHistoryCard from "../cards/CoachCard/CoachCourceHistoryCard";

function Items({ currentItems }) {
  if (!Array.isArray(currentItems) || currentItems.length === 0) {
    return (
      <div className="d-flex justify-content-center custom-container">
          <NoData page="coach-Cources-History" />
      </div>
  )
  }

  return (
    <>
      {currentItems.map((item, index) => (
        <div className="col-6 px-2" key={index}>
          <CoachCourceHistoryCard item={item} />
        </div>
      ))}
    </>
  );
}

const CoachHistoryPaginate = ({ slug, activeTab }) => {
  const dispatch = useDispatch();
  const coachDetail = useSelector(selectCoachDetail);

  const [itemOffset, setItemOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalItem, setTotalItem] = useState(0);
  const itemsPerPage = 8;

  const Classes =
    activeTab === "all-class"
      ? coachDetail?.all_classes?.data
      : activeTab === "previous-class"
      ? coachDetail?.past_classes?.data
      : coachDetail?.upcoming_classes?.data;

  // Fetch coach data when the page or activeTab changes
  useEffect(() => {
    const fetchCoaches = async () => {
      const result = await getCoachDetail({
        id: slug,
        all_per_page: itemsPerPage,
        upcoming_per_page: itemsPerPage,
        past_per_page: itemsPerPage,
        page: currentPage + 1, // Fetch the current page data
      });

      // Set the total number of items for pagination
      const totalClassesCount =
        activeTab === "all-class"
          ? result?.data?.all_classes?.total
          : activeTab === "previous-class"
          ? result?.data?.past_classes?.total
          : result?.data?.upcoming_classes?.total;

      setTotalItem(totalClassesCount || 0);
      dispatch(setCoach(result?.data || []));
    };

    fetchCoaches();
  }, [currentPage, activeTab, slug, dispatch]);

  // Calculate the total number of pages for pagination
  const pageCount = Math.ceil(totalItem / itemsPerPage);
  console.log(pageCount ,'page')

  // Handle page click event to set current page and offset
  const handlePageClick = (event) => {
    const newOffset = event.selected * itemsPerPage;
    setItemOffset(newOffset);
    setCurrentPage(event.selected);
  };

  return (
    <>
      <div className="d-flex flex-wrap">
        <Items currentItems={Classes} />
      </div>
      
        <div className="d-flex justify-content-center">
          <ReactPaginate
            breakLabel="..."
            nextLabel={currentPage === pageCount - 1 ? null : "Next"}
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel={currentPage === 0 ? null : "Previous"}
            renderOnZeroPageCount={null}
            className="paginationComponent"
            containerClassName="pagination"
            previousClassName={
              currentPage === 0 ? "previous hide-arrow" : "previous"
            }
            nextClassName={
              currentPage === pageCount - 1 ? "hide-next-arrow" : "next"
            }
          />
        </div>
     
    </>
  );
};

export default CoachHistoryPaginate;
