import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

//importing components
import Account from "../account/Account";
import EditProfile from "../../components/Modals/EditProfile";
import ShareModal from "../../components/Modals/ShareModal";

//importing image
import Button from "../../components/common/button/Button";
import editIcon from "../../assets/images/Profile/Icon.svg";
import share from "../../assets/images/Profile/shareIcon.svg";
import noProfileAvatar from "../../assets/images/Profile/noProfileAvatar.svg";


//importing redux
import { selectUserDetails } from "../../redux/userSlice";
import EditPreferenceTopic from "../../components/Modals/EditPreferenceTopic";
import { selectLearnAndEarnSubscriptionDetail } from "../../redux/learnAndEarnSubscriptionSlice";
// import { getUserStats } from "../../services/user.service";


const Profile = () => {
    const { t } = useTranslation();
    const userDetail = useSelector(selectUserDetails);
    const [show, setShow] = useState(false);
    const [isShow, setIsShow] = useState(false);
    const [selectedItem, setSelectedItem] = useState([]);
    const [shareModal, setShareModal] = useState(false);

    const subscriptionDetail = useSelector(selectLearnAndEarnSubscriptionDetail)
    // const [userStats, setUserStats] = useState()

    //     /**
    //        * @function fetchUserStats
    //        * @params
    //        * @description used to fetch user Stats details
    //    */
    //     const fetchUserStats = async () => {
    //         const result = await getUserStats();
    //         setUserStats(result?.data)
    //     };

    //     useEffect(() => {
    //         fetchUserStats();
    //         // eslint-disable-next-line
    //     }, []);

    useEffect(() => {
        if (userDetail?.categories?.length > 0) {
            setSelectedItem(userDetail?.categories?.map(item => item?.id))
        } else {
            setSelectedItem([])
        }
    }, [userDetail])
    const handleShareModal = () => {
        setShareModal(!shareModal)
    }

    // console.log(userDetail?.news_letter_subscription,'userDetail?.news_letter_subscription')

    return (
        <Account>
            <div className="profile-main-container">
                <h3 className="fw-bold mb-4">{t("personalInformation")}</h3>
                <div className="shadow-sm w-100 p-sm-4 p-1 mb-3">
                    <div className="d-flex justify-content-between">
                        <div>
                            <p className="fw-bold">{t("profilePhoto")}</p>
                            <div className=" "  >
                                <img
                                    src={userDetail?.image || noProfileAvatar}
                                    alt="user"
                                    className="w-25 rounded-circle overflow-hidden aspect-ratio-1x1"
                                />
                            </div>
                        </div>
                        <div onClick={() => setShow(true)} style={{ width: "120px" }}>
                            <Button name={t("edit")} btnType="whiteButton" icon={editIcon} />
                        </div>
                    </div>
                    {/* <div className="mt-5 d-md-flex d-column gap-1">
                        <div className="p-3 w-100 rounded mb-1">
                            <p className="m-0">{t("classAttended")}</p>
                            <p className="m-0 text-primary fs-3 fw-bold">{userStats?.total_classes_attended}</p>
                        </div>
                        <div className="p-3 w-100 rounded mb-1">
                            <p className="m-0">{t("myCertificate")}</p>
                            <p className="m-0 text-primary fs-3 fw-bold">{userStats?.total_certificates}</p>
                        </div>
                        <div className="p-3 w-100 rounded mb-1">
                            <p className="m-0">{t("minutesSpent")}</p>
                            <p className="m-0 text-primary fs-3 fw-bold">{Math.floor(userStats?.total_hours_spent / 60)}</p>
                        </div>
                    </div> */}

                    <div className="d-flex gap-md-5 gap-2 mt-5">
                        <p className="text-secondary w-50">{t("firstName")}</p>
                        <div className="d-flex gap-md-5 gap-2 w-100">
                            <p>:</p>
                            <p className="text-capitalize">{userDetail?.first_name}</p>
                        </div>
                    </div>
                    <div className="d-flex gap-md-5 gap-2">
                        <p className="text-secondary w-50">{t("lastName")}</p>
                        <div className="d-flex gap-md-5 gap-2 w-100">
                            <p>:</p>
                            <p className="text-capitalize">{userDetail?.last_name}</p>
                        </div>
                    </div>
                    <div className="d-flex gap-md-5 gap-2">
                        <p className="text-secondary w-50">{t("phoneNumber")}</p>
                        <div className="d-flex gap-md-5 gap-2 w-100">
                            <p>:</p>
                            <p className="text-capitalize">{userDetail?.phone_number}</p>
                        </div>
                    </div>
                    <div className="d-flex gap-md-5 gap-2">
                        <p className="text-secondary w-50">{t("emailAddress")}</p>
                        <div className="d-flex gap-md-5 gap-2 w-100">
                            <p>:</p>
                            <p>{userDetail?.email}</p>
                        </div>
                    </div>
                    {userDetail?.country?.name && (
                        <div className="d-flex gap-md-5 gap-2">
                            <p className="text-secondary w-50">{t("country")}</p>
                            <div className="d-flex gap-md-5 gap-2 w-100">
                                <p>:</p>
                                <p className="text-capitalize">{userDetail?.country?.name}</p>
                            </div>
                        </div>
                    )}
                    {userDetail?.dob && (
                        <div className="d-flex gap-md-5 gap-2">
                            <p className="text-secondary w-50">{t("dateOfBirth")}</p>
                            <div className="d-flex gap-md-5 gap-2 w-100">
                                <p>:</p>
                                <p className="text-capitalize">{userDetail?.dob}</p>
                            </div>
                        </div>
                    )}
                    {userDetail?.gender && (
                        <div className="d-flex gap-md-5 gap-2">
                            <p className="text-secondary w-50">{t("gender")}</p>
                            <div className="d-flex gap-md-5 gap-2 w-100">
                                <p>:</p>
                                <p className="text-capitalize">{userDetail?.gender}</p>
                            </div>
                        </div>
                    )}
                    {/* <div className="d-flex gap-md-5 gap-2">
                    <p className="text-secondary w-50">{t("timeZone")}</p>
                    <div className="d-flex gap-md-5 gap-2 w-100">
                    <p>:</p>
                    <p className="text-capitalize">{userDetail?.timezone}</p>
                </div>
                </div> */}
                </div>
                <div className="shadow-sm w-100 p-4 mb-3">
                    <div className="d-flex align-items-center justify-content-between">
                        <p className="fw-bold">{t("preferenceTopics")}</p>
                        <div style={{ width: "120px" }}>
                            <Button
                                btnType="whiteButton"
                                name={t("edit")}
                                icon={editIcon}
                                onClick={() => setIsShow(true)}
                            />
                        </div>
                    </div>
                    <div className="row">
                        {userDetail?.categories?.map((item, index) => {
                            return (
                                <div className="col-3 my-2" key={`category-${index}`}>
                                    <Button btnType="whiteButton" name={item?.name} />
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className="form-check d-flex gap-2 mb-3">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckChecked"
                        name="privacy_policy"
                        checked={userDetail?.news_letter_subscription  === '1'}
                    //   onChange={handleCheckBox}
                    />
                    
                    <p className="m-0 fs-6">{t("newsLetter")}</p>
                </div>
                <div className="shadow-sm w-100 p-4 mb-5">
                    <p className="fw-bold">{t("inviteYourFriends")}</p>
                    <p className="text-secondary">Friend, let’s learn together and grow together. Sign up for ReSkills Live Learning online platform.</p>
                    <div className='my-3 d-flex justify-content-between'>
                        <div className='fw-semibold text-primary'>{subscriptionDetail?.referral_code_url}</div>
                        <div onClick={handleShareModal}>
                            <Button
                                name={"Share"}
                                icon={share}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <EditProfile show={show} setShow={setShow} />
            <EditPreferenceTopic
                isShow={isShow}
                setIsShow={setIsShow}
                selectedItem={selectedItem}
                setSelectedItem={setSelectedItem}
            />
            <ShareModal show={shareModal} handleClose={handleShareModal} shareUrl={subscriptionDetail?.referral_code_url} />

        </Account>
    );
};

export default Profile;
